import { useEffect, useState } from 'react';
import useAPI from './useAPI';

import { NASConfigurationResponse, NASConnectionResponse, NASLastSyncResponse } from '../../shared/models/infra/NAS';
import { APIError } from '../../shared/api_errors';
import { useProfile } from '../useProfile';
import { useAuth } from '../useAuth';

export type ConnectionStatuses = Map<string, NASConnectionResponse | APIError>;

export interface APINASRouterOSValue {
	connectionStatus: NASConnectionResponse | undefined;
	multipleConnectionStatus: Map<string, NASConnectionResponse> | undefined;
	configuration: NASConfigurationResponse | undefined;
	sync: NASLastSyncResponse | undefined;
	loadingConnectionStatus: boolean;
	loadingMultipleConnectionStatus: Map<string, boolean> | undefined;
	loadingConfiguration: boolean;
	loadingSync: boolean;
	actionLoading: boolean;
	error: APIError | undefined;

	clear: () => void;
	clearError: () => void;
	refresh: () => void;

	applyConfiguration: () => Promise<boolean>;
	runSync: () => Promise<boolean>;
}

export class APINASOptions {
	nasId?: string = undefined;
	nasIds?: string[] = undefined;
	mustFetchMultipleConnectionStatus: boolean = false;
	mustFetchConnectionStatus: boolean = false;
	mustFetchConfiguration: boolean = false;
	mustFetchSync: boolean = false;
	asSaas: boolean = false;

	constructor(options?: Partial<APINASOptions>) {
		if (options) {
			Object.assign(this, options);
		}
	}
}

export default function useAPINASRouterOS(options?: Partial<APINASOptions>): APINASRouterOSValue {
	const {
		nasId,
		nasIds,
		mustFetchConnectionStatus,
		mustFetchMultipleConnectionStatus,
		mustFetchConfiguration,
		mustFetchSync,
		asSaas,
	} = new APINASOptions(options);

	const { tenant, saasTenant } = useProfile();
	const { user } = useAuth();
	const api = useAPI(true, user?.access_token, asSaas ? saasTenant || tenant : tenant);

	const [connectionStatus, setConnectionStatus] = useState<NASConnectionResponse | undefined>(undefined);
	const [multipleConnectionStatus, setMultipleConnectionStatus] = useState<
		Map<string, NASConnectionResponse> | undefined
	>(undefined);
	const [configuration, setConfiguration] = useState<NASConfigurationResponse | undefined>(undefined);
	const [sync, setSync] = useState<NASLastSyncResponse | undefined>(undefined);

	const [loadingConnectionStatus, setLoadingConnectionStatus] = useState<boolean>(false);
	const [loadingMultipleConnectionStatus, setLoadingMultipleConnectionStatus] = useState<
		Map<string, boolean> | undefined
	>();
	const [loadingConfiguration, setLoadingConfiguration] = useState<boolean>(false);
	const [loadingSync, setLoadingSync] = useState<boolean>(false);
	const [actionLoading, setActionLoading] = useState<boolean>(false);
	const [error, setError] = useState<APIError | undefined>();

	function clearError() {
		setError(undefined);
	}

	async function fetchConnectionStatus() {
		if (!nasId) {
			setConnectionStatus(undefined);
			return;
		}

		try {
			setLoadingConnectionStatus(true);
			const result = await api.get(`/infra/nas/${nasId}/connection`);
			setConnectionStatus(result);
		} catch (err) {
			handleAPIError(err);
		} finally {
			setLoadingConnectionStatus(false);
		}
	}

	async function fetchMultipleConnectionStatuses() {
		if (!nasIds) {
			setConnectionStatus(undefined);
			setLoadingMultipleConnectionStatus(undefined);
			return;
		}

		try {
			// Convert nas_id to array if it's a single string
			setLoadingMultipleConnectionStatus(new Map(nasIds.map((nasId) => [nasId, true])));

			// Fetch connection status for each nas_id and progressively update state
			const promises = nasIds.map(async (id) => {
				const result = await api.get(`/infra/nas/${id}/connection`);
				setMultipleConnectionStatus((prev) => {
					const newStatus = new Map(prev);
					newStatus.set(id, result);
					return newStatus;
				});
				setLoadingMultipleConnectionStatus((prev) => {
					const newStatus = new Map(prev);
					newStatus.set(id, false);
					return newStatus;
				});
			});

			// Wait for all the requests to finish
			await Promise.all(promises);
		} catch (error) {
			setLoadingMultipleConnectionStatus(undefined);
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setLoadingMultipleConnectionStatus(undefined);
		}
	}

	async function fetchConfigurations() {
		if (!nasId) {
			setConfiguration(undefined);
			return;
		}

		try {
			setLoadingConfiguration(true);
			const result = await api.get(`/infra/nas/${nasId}/configuration`);
			setConfiguration(result);
		} catch (err) {
			handleAPIError(err);
		} finally {
			setLoadingConfiguration(false);
		}
	}

	async function fetchSync() {
		if (!nasId) {
			setSync(undefined);
			return;
		}

		try {
			setLoadingSync(true);
			const result = await api.get(`/infra/nas/${nasId}/sync`);
			setSync(result);
		} catch (err) {
			handleAPIError(err);
		} finally {
			setLoadingSync(false);
		}
	}

	async function applyConfiguration(): Promise<boolean> {
		if (!nasId) return false;

		try {
			setActionLoading(true);
			await api.put(`/infra/nas/${nasId}/configuration`);
			await fetchConfigurations();
			return true;
		} catch (err) {
			handleAPIError(err);
			return false;
		} finally {
			setActionLoading(false);
		}
	}

	async function runSync(): Promise<boolean> {
		if (!nasId) return false;

		try {
			setActionLoading(true);
			await api.put(`/infra/nas/${nasId}/sync`);
			await fetchSync();
			return true;
		} catch (err) {
			handleAPIError(err);
			return false;
		} finally {
			setActionLoading(false);
		}
	}

	function handleAPIError(err: unknown) {
		if (typeof err === 'string') {
			setError(new APIError(err));
		} else if (err instanceof Error) {
			setError(new APIError(err.message));
		} else if (err instanceof APIError) {
			setError(err);
		}
	}

	function clear() {
		setConnectionStatus(undefined);
		setConfiguration(undefined);
		setSync(undefined);
		setError(undefined);
	}

	function fetch() {
		mustFetchConnectionStatus && fetchConnectionStatus();
		mustFetchMultipleConnectionStatus && fetchMultipleConnectionStatuses();
		mustFetchConfiguration && fetchConfigurations();
		mustFetchSync && fetchSync();
	}

	useEffect(() => {
		fetch();
	}, [nasId, nasIds, mustFetchConnectionStatus, mustFetchConfiguration, mustFetchSync]);

	return {
		connectionStatus,
		multipleConnectionStatus,
		configuration,
		sync,
		loadingConnectionStatus,
		loadingMultipleConnectionStatus,
		loadingConfiguration,
		loadingSync,
		actionLoading,
		error,
		clear,
		clearError,
		refresh: fetch,
		applyConfiguration,
		runSync,
	};
}

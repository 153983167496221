import { useState } from 'react';
import useAPI from './useAPI';

import { APIError } from '../../shared/api_errors';
import { useAuth } from '../useAuth';
import { useProfile } from '../useProfile';

export type ImportType = 'commitment' | 'testing';

export interface ImportTXTPayload {
	currency_id: string;
	nas_id: string;
	pools: string;
	profiles: string;
	secrets: string;
}

export interface ImportConnectingPayload {
	type: ImportType;
	currency_id: string;
	nas: string[];
}

export default function useAPIISImport() {
	const { tenant, saasTenant } = useProfile();
	const { user } = useAuth();

	const api = useAPI(true, user?.access_token, saasTenant || tenant);

	const [importLogs, setImportLogs] = useState<string[] | undefined>(undefined);
	const [importLoading, setImportLoading] = useState<boolean>(false);

	const [wipeLoading, setWipeLoading] = useState<boolean>(false);
	const [wipeResult, setWipeResults] = useState<string | undefined>(undefined);

	const [error, setError] = useState<APIError | undefined>();

	async function fetchImport(isp_id: string, payload: ImportConnectingPayload) {
		setImportLogs(undefined);
		setImportLoading(true);

		try {
			const result = await api.put(`/import/${isp_id}`, { body: payload });
			setError(undefined);
			setImportLogs(result.logs);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setImportLoading(false);
		}
	}

	async function fetchImportTXT(isp_id: string, payload: ImportTXTPayload) {
		setImportLogs(undefined);
		setImportLoading(true);

		try {
			const result = await api.put(`/import/${isp_id}/txt`, { body: payload });
			setError(undefined);
			setImportLogs(result.logs);
			setImportLoading(false);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setImportLoading(false);
		}
	}

	async function fetchWipe(ispId: string, ispName: string, nasIds?: string[]) {
		setWipeResults(undefined);
		setWipeLoading(true);

		try {
			const result = await api.del(`/import/${ispId}/wipe`, { body: { isp_name: ispName, nas_ids: nasIds } });
			setError(undefined);
			setWipeResults(result.message);
			return result;
		} catch (error) {
			if (typeof error === 'string') {
				setError(new APIError(error));
			} else if (error instanceof Error) {
				setError(new APIError(error.message));
			} else if (error instanceof APIError) {
				setError(error);
			}
		} finally {
			setWipeLoading(false);
		}
	}

	return { fetchImport, fetchImportTXT, fetchWipe, importLogs, wipeResult, importLoading, wipeLoading, error };
}
